<template>
    <div class="inputBox">
        <label v-if="label!=''">
            <span>{{label}}</span>
        </label>
        <el-input :type="inputType" v-model="value" :placeholder="placeholder" :disabled="disabled" autocomplete="off" />
    </div>
</template>

<script>
    export default {
        name: "inputBox",
        props:{
            label:{ // label 文字  可以不传
                type:String,
                default:'',
            },
            inputType:{ // input类型 默认text
                type:String,
                default:'text',
            },
            disabled:{ // 是否禁用 false不禁用 true禁用
                type:Boolean,
                default:false,
            },
            placeholder:{ // placeholder文字
                type:String,
                default:'请输入',
            },
            iptVal:{ // 传输input框默认值
                type:String,
                default:'',
            }
        },
        data(){
            return {
                'value':this.iptVal,
            }
        },
        watch:{
            value(val){
                this.$emit('input',this.value);
            },
            iptVal(){
                this.value = this.iptVal;
            },
        },
    }
</script>

<style scoped lang="scss">
    .inputBox{
        width: 100%;
        label{
            display: flex;
            justify-content: space-between;
            margin-bottom: 8px;
            span{
                font-size:16px;
                font-weight:500;
                color:rgba(120,128,144,1);
                line-height:22px;
            }
        }
        input{
            width: 100%;
            height: 46px;
            box-sizing: border-box;
            border-radius:4px;
            border:1px solid rgba(223,226,231,1);
            font-size: 16px;
            font-weight: 400;
            padding: 0 10px;
            box-sizing: border-box;
            &.cursor{
                cursor: pointer;
            }
        }
    }
</style>