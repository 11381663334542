<template>
    <div class="code-main">
        <div class="code-item" :class="{'code-item-focus':getFocudNum == 0&&code.length == 0}">{{code[0]}}</div>
        <div class="code-item" :class="{'code-item-focus':getFocudNum == 0&&code.length == 1}">{{code[1]}}</div>
        <div class="code-item" :class="{'code-item-focus':getFocudNum == 0&&code.length == 2}">{{code[2]}}</div>
        <div class="code-item" :class="{'code-item-focus':getFocudNum == 0&&code.length == 3}">{{code[3]}}</div>
        <div class="code-item" :class="{'code-item-focus':getFocudNum == 0&&code.length == 4}">{{code[4]}}</div>
        <div class="code-item" :class="{'code-item-focus':getFocudNum == 0&&code.length == 5}">{{code[5]}}</div>
        <input class="code-input" v-model="code" maxlength="6" type="number"
               onkeyup="this.value=this.value.replace(/\D/g,'')"
               onafterpaste="this.value=this.value.replace(/\D/g,'')"
               @focus="getFocud" @blur="getBlur"
        />
    </div>
</template>

<script>
    export default {
        name: "CodeInput",
        data() {
            return {
                'code': '',
                'getFocudNum':-1
            }
        },
        watch:{
            code(){
                if(this.code.length > 5){
                    this.code = this.code.slice(0,6);
                }
                this.$emit("input",this.code);
            }
        },
        methods:{
            getFocud(){
                this.getFocudNum = 0;
            },
            getBlur(){
                this.getFocudNum = -1;
            }
        }
    }
</script>
<style scoped lang="scss">
    .code-main{
        position: relative;
        overflow: hidden;
        display: flex;
        justify-content: space-between;
        margin-bottom: 14px;
        .code-input {
            height: 100%;
            position: absolute;
            left: 0;
            width: 120%;
            outline: none;
            color: transparent;
            text-shadow: 0 0 0 transparent;
            outline: none;
        }
        .code-main {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
        }
        .code-item {
            width: 50px;
            height: 50px;
            border: 1px solid #000000;
            border-radius: 4px;
            outline: none;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 16px;
            color: #000;
            position: relative;
            &.code-item-focus:before{
                content:"";
                display: block;
                width: 2px;
                height: 16px;
                left:50%;
                top: 50%;
                opacity: 0;
                background: #000;
                animation: changes .5s linear infinite alternate;
            }
        }
    }
    @keyframes changes {
        from { opacity: 0; }
        to { opacity: 1; }
    }
</style>