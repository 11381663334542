<template>
    <div class="password">
        <label v-if="label != ''">
            <span>{{label}}</span>
            <router-link
                to="/forgetpwd"
                tag="span"
                class="forget-pass"
                v-if="forgetPass=='1'"
            >
                <!--忘记密码?-->
            </router-link>
        </label>
        <div class="pswd-box">
            <el-input type="password" v-model="value" autocomplete="off" :placeholder="placeholder" v-if="isEye" />
            <el-input type="test"  v-model="value" autocomplete="off"  :placeholder="placeholder" v-else/>
            <span>
                <i class="yw yxrwyanjing-guan" v-if="isEye" @click="isEye = false"></i>
                <i class="yw yxrwyanjing-kai" v-else @click="isEye = true"></i>
            </span>
        </div>
        <div class="pass-error" v-if="isValueError">{{valueError}}</div>
        <ul class="passGrade" v-if="isGrade == '1' && value.length != ''">
            <li :class="{'passGradeItem1':passGrade>0}"></li>
            <li :class="{'passGradeItem1':passGrade>0}"></li>
            <li :class="{'passGradeItem2':passGrade>1}"></li>
            <li :class="{'passGradeItem2':passGrade>1}"></li>
            <li :class="{'passGradeItem3':passGrade>2}"></li>
            <li :class="{'passGradeItem3':passGrade>2}"></li>
            <li :class="{'passGradeItem4':passGrade>3}"></li>
        </ul>
        <p class="passGradeTs" v-if="isGrade == '1' && value.length != ''">
            {{passGrade==0?'弱的很':(passGrade==1?'还行吧':(passGrade==2?'凑合用':(passGrade==3?'不错哦':'很强大')))}}
        </p>
    </div>
</template>

<script>
    export default {
        name: "password",
        props:{
            isGrade:{
                type:String,
                default: '0'
            },
            label:{
                type:String,
                default: '密码'
            },
            forgetPass:{// 是否显示忘记密码按钮  只有登录页面需要这个参数
                type:String,
                default:'0',
            },
            placeholder:{
                type:String,
                default: '请输入密码'
            }
        },
        data(){
            return{
                'value':'',
                'passGrade':0,
                'isEye':true,
                'valueError':'',
                'isValueError':false,
            }
        },
        watch:{
            value(newVal, oldVal){// 监听新密码等级
                this.passGrade = this.checkStrong(this.value);
                this.$emit('input',this.value)
            },
            deep: true,
        },
        methods:{
            // 判断密码强软
            checkStrong(sValue) {
                let modes = 0;
                //正则表达式验证符合要求的
                if (sValue.length < 6) return modes;
                if (/\d/.test(sValue)) modes++; //数字
                if (/[a-z]/.test(sValue)) modes++; //小写
                if (/[A-Z]/.test(sValue)) modes++; //大写
                if (/\W/.test(sValue)) modes++; //特殊字符
                //逻辑处理
                if(modes == 1){ return 1; }
                if(modes == 2){ return 2; }
                if(modes == 3){ return 3; }
                if(modes == 4){ return sValue.length < 4 ? 3 : 4; }
                return modes;
            }
        }
    }
</script>

<style scoped lang="scss">
    .password{
        &>label{
            display: flex;
            justify-content: space-between;
            margin-bottom: 8px;
            span{
                font-size:16px;
                font-weight:500;
                color:rgba(120,128,144,1);
                line-height:22px;
            }
            .forget-pass{
                font-size: 14px;
                color: #788090;
                cursor: pointer;
            }
        }
        .pswd-box{
            width: 100%;
            height: 46px;
            box-sizing: border-box;
            border-radius:4px;
            border:1px solid rgba(223,226,231,1);
            font-size: 16px;
            font-weight: 400;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            overflow: hidden;
            /deep/.el-input__inner{
                border: 0;
                width: 110%;

            }
            input{
                flex: 1;
            }
            span{
                display: inline-block;
                margin-right: 16px;
                cursor: pointer;
                i{
                    color: #AEB1B7;
                }
            }
            .pass-error{
                color: #f56c6c;
                font-size: 12px;
                line-height: 1;
                padding-top: 4px;
            }
        }
        .passGrade{
            display: flex;
            margin: 8px 0;
            li{
                width:24px;
                height:6px;
                background:rgba(234,237,241,1);
                border-radius:4px;
                margin-right: 4px;
                &.passGradeItem1{
                    background: #26C4B8;
                }
                &.passGradeItem2{
                    background: #F7B94D;
                }
                &.passGradeItem3{
                    background: #FE6664;
                }
                &.passGradeItem4{
                    background: #FF6E5A;
                }
            }
        }
        .passGradeTs{
            font-size:14px;
            font-weight:400;
            color:rgba(120,128,144,1);
            line-height:20px;
        }
    }
    @media screen and (max-width: 980px) {
        .password {
            .passGrade {
                width: 100%;
            }
        }
    }
</style>