<template>
    <div class="forgetpwd">
        <div class="forgetpwdL"><ent-explain-vue /></div>
        <div class="forgetpwdR">
            <div class="forget-pass">
                <div class="retrieve-one" v-show="sep == 0">
                    <h3 class="title">找回密码</h3>
                    <input-box-vue v-model="personal.tel" placeholder="请输入您的手机号"/>
                    <button class="next-btn" @click="nextFn(1)" style="margin-top: 60px;" v-if="isBtn.isTelBtn">下一步</button>
                    <button class="not-next-btn" style="margin-top: 60px;" v-else>下一步</button>
                </div>
                <div class="retrieve-tow" v-show="sep == 1">
                    <div class="previous" @click="nextFn(0)">&lt;返回重新输入手机号</div>
                    <h3 class="title">验证手机号</h3>
                    <p class="code-tishi" v-if="isBtn.isCodeTs">请输入发送至<span>{{personal.tel}}</span>的验证码，有效期十分钟。如未收到，请尝试重新获取验证码。</p>
                    <p class="code-cuowu" v-else>验证码错误，请重新输入。</p>
                    <code-vue v-model="personal.code"/>
                    <span class="getCode1" v-if="isBtn.isCodeTs">{{second}}秒之后可重新获取验证码</span>
                    <span class="getCode2" v-else>重新获取验证码</span>
                    <button class="next-btn" @click="nextFn(2)" style="margin-top: 88px;" v-if="isBtn.isCodeBtn">下一步</button>
                    <button class="not-next-btn" style="margin-top: 88px;" v-else>下一步</button>
                </div>
                <div class="retrieve-three" v-show="sep == 2">
                    <h3 class="title">设置新密码</h3>
                    <password-vue v-model="personal.newPass" isGrade="1" label="新密码" class="mb40"/>
                    <password-vue v-model="personal.newPassS" isGrade="0" label="再次输入新密码" class="mb40"/>
                    <button class="next-btn" @click="complete" v-if="isBtn.isPassBtn" style="margin-top: 28px;">下一步</button>
                    <button class="not-next-btn" v-else style="margin-top: 28px;">下一步</button>
                </div>
                <div class="forget-success" v-show="isSuccess">
                    <div class="forget-success-box"></div>
                    <div class="forget-success-center">
                        <img src="@/assets/img/success.png" alt="设置密码成功">
                        <h3>设置密码成功</h3>
                        <p>{{second}}秒后跳转到登录页，如果浏览器没有跳转，您也可以直接</p>
                        <router-link to="/login" tag="button" class="toLoginBtn">去登录</router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import codeVue from '../../components/code'
    import passwordVue from '../../components/password'
    import inputBoxVue from '../../components/inputBox'
    import EntExplainVue from './com/EntExplain.vue'
    export default {
        name: "forgetpwd",
        components:{ codeVue, passwordVue, inputBoxVue, EntExplainVue },
        data(){
            return{
                'sep': 0,
                'second': 60,
                'personal':{
                    'tel':'',
                    'code':'',
                    'newPass':'',
                    'newPassS':'',
                },
                'isBtn':{
                    'isTelBtn':false, // 手机界面下一步开关
                    'isCodeBtn':false, // 验证码界面下一步开关
                    'isPassBtn':false, // 设置密码界面下一步开关
                    'isCodeTs':true, // code 提示语
                },
                'isSuccess':false,
                'passGrade':0,
                'obj':null,
            }
        },
        watch:{
            personal:{
                handler(newVal,val){
                    if(newVal.tel.length == 11){
                        this.isBtn.isTelBtn = true;
                    }else{
                        this.isBtn.isTelBtn = false;
                    }
                    if(newVal.code.length  == 6){
                        this.isBtn.isCodeBtn = true;
                    }else{
                        this.isBtn.isCodeBtn = false;
                    }
                    if(newVal.newPass == '' || newVal.newPassS == '' || newVal.newPass.length < 7 || newVal.newPassS.length < 7){
                        this.isBtn.isPassBtn = false;
                    }else{
                        this.isBtn.isPassBtn = true;
                    }
                },
                deep:true,
            }
        },
        methods:{
            // 下一步按钮
            nextFn(index){
                if(index == 1){
                    if(!this.$cm.checkPhone(this.personal.tel)){ return this.$layer.msg('请输入正确手机号'); }
                    this.getCode(index);
                }
                if(index == 2){
                    if(this.personal.code == ''){ return this.$layer.msg('请输入验证码'); }
                    if(this.personal.code.length != 6){ return this.$layer.msg('请输入正确的验证码'); }
                    this.checkCaptcha(index);
                }
            },
            // 获取验证码
            getCode(index){
                this.axios('POST',`${this.$phpBaseURL}auth/v1/forgetPassword/captcha`,{
                    mobile:this.personal.tel,
                }).then(res=>{
                    if(res.code == 1){
                        this.second = 60;
                        this.$message({ message: '获取验证码成功', type: 'success' });
                        this.secondTime();
                        this.sep = index;
                    } else if(res.code == 2){
                        //token过期处理
                        this.common.tokenExpired(res.msg);
                    }else{
                        this.$message({ message: res.msg, type: 'warning' });
                    }
                })
            },
            // 校对验证码
            checkCaptcha(index){
                this.axios('post',`${this.$phpBaseURL}auth/v1/forgetPassword/checkCaptcha`,{
                    mobile:this.personal.tel,
                    captcha:this.personal.code,
                }).then(res => {
                    if(res.code == 1){
                        this.sep = index;
                    } else if(res.code == 2){
                        //token过期处理
                        this.common.tokenExpired(res.msg);
                    } else{
                        this.$message({ message: res.msg, type: 'warning' });
                    }
                })
            },
            // 验证码即时
            secondTime(){
                let that = this;
                let time =null
                time = setInterval(() => {
                    if(that.second != 0){
                        that.second--;
                    }else{
                        this.isBtn.isSecond = false;
                        clearInterval(time)
                    }
                },1000)
            },
            // 完成按钮
            complete(){
                if(this.personal.newPass == ''){ return this.$message({ message:'请输入新密码', type: 'warning' }); }
                if(this.personal.newPass.length < 8 || this.personal.newPassS.length < 8){ return this.$message({ message:'请输入新密码', type: 'warning' }); }
                if(this.personal.newPass != this.personal.newPassS){ return this.$message({ message:'两次密码不一致', type: 'warning' }); }
                this.axios('put',`${this.$phpBaseURL}auth/v1/forgetPassword`,{
                    mobile: this.personal.tel,
                    captcha: this.personal.code,
                    password: this.personal.newPass,
                    password_confirmation: this.personal.newPassS,
                }).then(res=> {
                    if(res.code == 1){
                        let that = this;
                        this.second = 3;
                        this.isSuccess = true;
                        this.secondTime();
                        setTimeout(function () {
                            that.$router.push('/login');
                        },3000)
                    } else if(res.code == 2){
                        //token过期处理
                        this.common.tokenExpired(res.msg);
                    }else{
                        this.$message({ message: res.msg, type: 'warning' });
                    }
                })

            },
        },
    }
</script>

<style scoped lang="scss">
    .forgetpwd{
        width: 100%;
        height: 100%;
        display: flex;
        .forgetpwdL,.forgetpwdR{
            width: 50%;
            box-sizing: border-box;
        }
        .title{
            font-size:28px;
            font-weight:500;
            color:rgba(25,39,68,1);
            line-height:40px;
            margin-bottom: 32px;
        }
        .forgetpwdL{background: #f5f5f5}
        .forgetpwdR{
            padding: 0 150px;

            .forget-pass{

                .retrieve-one{
                    padding-top: 100px;
                }
                .retrieve-tow{
                    padding-top: 100px;
                    .previous{
                        font-size:14px;
                        font-weight:400;
                        color:rgba(120,128,144,1);
                        line-height:20px;
                        margin-bottom: 16px;
                    }
                    .code-tishi{
                        font-size:16px;
                        font-weight:400;
                        color:rgba(120,128,144,1);
                        line-height:22px;
                        margin-bottom: 24px;
                        span{
                            color: #192744;
                            font-size: 20px;
                        }
                    }
                    .code-cuowu{
                        font-size:16px;
                        font-weight:400;
                        color:rgba(254,102,100,1);
                        line-height:22px;
                        margin-bottom: 54px;
                    }
                    &>span{
                        font-size:18px;
                        font-weight:400;
                        color:rgba(120,128,144,1);
                        line-height:25px;
                    }
                    .getCode1{
                        font-size:18px;
                        font-weight:400;
                        color:rgba(120,128,144,1);
                        line-height:25px;
                        margin-bottom: 88px;
                    }
                    .getCode2{
                        font-size:16px;
                        font-weight:400;
                        color:rgba(71,134,255,1);
                        line-height:22px;
                        margin-bottom: 88px;
                    }
                }
                .retrieve-three{
                    padding-top: 100px;
                }
            }
        }
        .forget-success{
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1;
            .forget-success-box{
                width: 100%;
                height: 100%;
                background: rgba(0,0,0,.5);
            }
            .forget-success-center{
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                width:516px;
                height:326px;
                background:rgba(255,255,255,1);
                border-radius:4px;
                display: flex;
                flex-flow: column;
                align-items: center;
                h3{
                    font-size:20px;
                    font-weight:500;
                    color:rgba(25,39,68,1);
                    line-height:28px;
                    margin-bottom: 8px;
                }
                img{
                    width: 40px;
                    display: block;
                    margin: 65px auto 24px auto;
                }
                p{
                    width:266px;
                    font-size:14px;
                    font-weight:400;
                    color:rgba(173,179,188,1);
                    line-height:20px;
                    text-align: center;
                    margin-bottom: 24px;
                    span{
                        color: red;
                    }
                }
                .toLoginBtn{
                    width:60px;
                    height:32px;
                    background:rgba(255,110,90,1);
                    font-size:14px;
                    font-weight:400;
                    color:rgba(255,255,255,1);
                    line-height:22px;

                }
            }
        }
    }
    @media screen and (max-width: 980px){
        .forgetpwd{
            flex-flow: column;
            .forgetpwdL{
                display: none;
            }
            .forgetpwdR{
                width: 100%;
                padding: 0 20px;
            }
            .forget-success{
                .forget-success-center{
                    width: 400px;
                }
            }
        }
    }
</style>