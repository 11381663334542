<template>
    <div class="EntExplain">
        <div class="logo">
            <!--<img src="@/assets/img/logo.png" alt="">-->
        </div>
        <div class="EntExplain-center">
            <div class="EntExplain-title">
                <p>  </p>
                <p>  </p>
            </div>
            <div class="EntExplain-img">
                <img src="@/assets/img/EntExplainImg.png" alt="">
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "EntExplain"
    }
</script>

<style scoped lang="scss">
.EntExplain{
    padding-top: 20px;
    .logo{
        margin-bottom: 90px;
        padding-left: 50px;
        img{
            width: 130px;
            /*height: 36px;*/
        }
    }
    .EntExplain-center{
        .EntExplain-title{
            margin-bottom: 62px;
            p{
                text-align: center;
                font-size:20px;
                font-weight:400;
                color:rgba(120,128,144,1);
                line-height:30px;
            }
        }
        .EntExplain-img{
            width: 500px;
            margin: 0 auto;
            margin-top: 150px;
            img{
                width: 100%;
            }
        }
    }
}
</style>